<template>
  <div class="login-page">
    <div class="header">
      <img class="logo" src="../../assets/img-logo-hili.jpg" alt="">
    </div>
    <div class="content">
      <img class="image" :src="loginImage" />
      <div class="input-box">
        <div class="box-title">账户登录</div>
        <el-input class="row input" v-model="phone" :maxlength="11" placeholder="请输入手机号" ></el-input>
        <div class="row">
          <el-input class="short-input" v-model="pictureVerificationCode" placeholder="请输入图片验证码" ></el-input>
          <img class="verification-image" title="点击更换" :src="verificationImageUrl" @click="refreshVerificationImageUrl" />
        </div>
        <div class="row">
          <el-input class="short-input" v-model="phoneVerificationCode" placeholder="请输入短信验证码"></el-input>
          <el-button class="short-btn" type="primary" @click="sendMessageCode" :disabled="!captchaBtnVisible">{{ countdownPoint }}</el-button>
        </div>
        <el-button class="full-width" type="primary" @click="onLoginBtnClick" :loading="btnDisabled">登录</el-button>
      </div>
    </div>

    <div class="footer">
      <span>版权所有2021©天津海狸新能源科技有限公司</span>&nbsp;
      <a href="https://beian.miit.gov.cn" target="_blank">津ICP备20004979号-2</a>
    </div>
  </div>
</template>

<script>
import loginImage from "@/assets/img-login-page.png";
import { securityImageCode, sendMessageCode, userLoginByPhone } from "@/api/login";
import { getUserRouter } from "@/api/jurisdiction";


import Reg from "@/js/reg";
// import mainRoutes  from '@/router/main'

//   mainRoutes[0].children.forEach(el => {
//     el.isMenu = false,
//     el.children.forEach(el2 => {
//       el2.isMenu = false
//     })
//   })

export default {
  name: "loginPage",
  data() {
    return {
      phone: "",
      pictureVerificationCode: "",
      phoneVerificationCode: "",
      captchaBtnVisible: true,
      countdown: 60,
      verificationImageUrl: "",
      countdownPoint: "获取验证码",
      loginImage,
      // limitMenu: mainRoutes[0].children,
      load: {},
      btnDisabled: false,
    };
  },
  mounted() {
    this.securityImageCode();

    if (process.env.NODE_ENV == "development") {
      /*this.phone = '17645652590'
      this.pictureVerificationCode = 1
      this.phoneVerificationCode = 1*/
    }
  },
  methods: {
    async securityImageCode() {
      const res = await securityImageCode({})
      this.verificationImageUrl = "data:image/png;base64," + res;
    },
    async sendMessageCode() {

      if (!Reg.isPoneAvailable(this.phone)) {
        this.$message.error('请输入正确手机号')
        return
      }
      if (!this.pictureVerificationCode) {
        this.$message({
          type: "error",
          message: "请填写图片验证码!",
        });
        return;
      }
      if(!this.captchaBtnVisible) {
        return
      }
      this.captchaBtnVisible = false;
      let params = {
        phone: this.phone,
        mgmtType: 1,
        pictureVerificationCode: this.pictureVerificationCode
      };
      try {
        await sendMessageCode(params);
        this.verificationCountDown();
      } catch (error) {
        this.captchaBtnVisible = true;
      }

    },
    verificationCountDown() {
      this.captchaBtnVisible = false;
      let self = this;
      function countDown() {
        if (self.countdown == 0) {
          self.captchaBtnVisible = true;
          self.countdown = 60;
          self.countdownPoint = "重新发送";
          self.refreshVerificationImageUrl();
        } else {
          self.countdownPoint = "重新发送(" + self.countdown + ")";
          self.countdown--;
          self.captchaTimer = setTimeout(countDown, 1000);
        }
      }
      countDown();
    },
    refreshVerificationImageUrl() {
      this.securityImageCode();
    },
    onLoginBtnClick() {
      this.refreshVerificationImageUrl();
      this.userLoginByPhone();
    },
    async userLoginByPhone() {
      if (!Reg.isPoneAvailable(this.phone)) {
        this.$message.error('请输入正确手机号')
        return
      }
      if (!this.phoneVerificationCode) {
        this.$message({
          type: "error",
          message: "请输入短信验证码",
        });
        return;
      }
      let params = {
        phoneVerificationCode: this.phoneVerificationCode,
        loginPhone: this.phone,
      };
      if ( this.btnDisabled) {
        return
      }
      this.btnDisabled = true
      try {
        await userLoginByPhone(params)
        /*const authPaths =  await getUserRouter({})
        const allPathArr = authPaths.ldata.map(el => el.subMenuList)
        const allPathArrFlat = allPathArr.flat()
        if (!allPathArrFlat.length) {
          this.$message.error('暂无菜单权限，请配置权限')
          return
        }
        const pathInfo = allPathArrFlat[0] || {}
        this.$router.push({path: pathInfo.path})*/
        this.$router.push({
          path: '/leaseholdMgmt/hello'
        })
        this.btnDisabled = false
      } catch (error) {
        this.btnDisabled = false
      }

      // this.load =  this.$loading({ text: '登录中...' });
      // const res = await getUserRouter().catch(err => {
      //     // this.load.close()
      // })
      // this.handleMenu(res.ldata)
    },
    // handleMenu(ldata) {
    //   /** 权限处理 */
    //   this.limitMenu.forEach(nowMe => {
    //     /*判断是否有当前一级菜单权限*/
    //     const flag = ldata.some(el => el.path.includes(nowMe.name) )
    //     if(flag) {
    //       nowMe.isMenu = true
    //       /** 找到当前一级菜单 */
    //       const mainFirstMenu = ldata.find(el => el.path.includes(nowMe.name))
    //       /** 遍历当前一级菜单 判断二级菜单权限 */
    //       nowMe.children.forEach(nowMeChild => {
    //         mainFirstMenu.subMenuList.forEach(mainFirstMenuChild => {
    //           if (nowMeChild.path == mainFirstMenuChild.path) {
    //             nowMeChild.isMenu = true
    //           }
    //         })
    //       })
    //     }
    //   });
    //   const firstMenu = this.limitMenu.find(el => el.isMenu == true)
    //   const secMenu = firstMenu.children.find(el => el.isMenu == true)
    //   localStorage.setItem('menus', JSON.stringify(this.limitMenu))
    //   setTimeout(() => {
    //     if (this.load.close) {
    //         this.load.close()
    //     }
    //     this.$router.push({name: secMenu.name})
    //     // this.$router.push({name: 'rent'})
    //   }, 1000)
    //   // console.log(this.limitMenu);
    // },
    async getUserRouter() {
      return await getUserRouter({})
    }
  }
};
</script>

<style lang="less" scoped>
.footer{
   position:absolute;
   bottom:5%;
   left:50%;
   transform:translateX(-75%);
   height:30px;
   line-height:30px;
   span{
     display:inline-block;
     margin-bottom:-5px;
   }
   a{
     position:absolute;
     width:180px;
     top:0px;
     left:340px;
     color:blue;
     font-weight:bold;
   }
}
.login-page {
  background: #fff;
  height: 100vh;
  .logo {
    height: 35px;
    overflow: hidden;
    margin: 11px 62px;
  }
  .header {
    width: 100%;
    height: 56px;
    background: #fff;
    border-bottom: 1px solid #bbb;
  }
  .image {
    display: inline-block;
    width: 600px;
    height: 600px;
  }
  .input-box {
    // position: absolute;
    // top: 80px;
    // right: 250px;
    width: 333px;
    height: 442px;
    box-shadow: 0px 3px 15px 0px rgba(170, 170, 170, 0.32);
    border-radius: 7px;
    padding: 22px;
    box-sizing: border-box;
    margin-top: 75px;
    .box-title {
      font-size: 19px;
      color: #000;
      padding-bottom: 25px;
    }
    .row {
      height: 32px;
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 16px;
      .short-input {
        width: 180px;
        margin-right: 10px;
        vertical-align: middle;
      }
      .verification-image {
        width: 92px;
        height: 32px;
        vertical-align: middle;
      }
      .short-btn {
        vertical-align: middle;
        width: 94px;
        padding:  12px 0;
      }
      .check-box {
        padding: 5px 0;
      }
      .text-btn {
        float: right;
      }
    }
  }
  .full-width {
    width: 100%;
  }
  .content {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: flex;
    align-items: center;

  }
}
</style>
