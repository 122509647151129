<template>
  <div class="hello-page">
    <div class="page-title">欢迎使用电池租赁运营管理系统！</div>
  </div>
</template>

<script>
export default {
  name: "hello"
}
</script>

<style scoped lang="less">
.hello-page {
  display: flex;
  flex-direction: column;
  .page-title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
    font-size: 40px;
    font-weight: bold;
  }
}
</style>
